<template>
  <div class="cta-credit">
    <img class="cta-credit__image" src="/assets/images/alpitour/statics/iStock-1180231917_2.png" alt="Credito non sufficiente?" />
    <div class="cta-credit__wrap">
      <h1 class="cta-credit__title vb-heading vb-heading--h1" v-html="'Welfare non sufficiente?'" />
      <p class="cta-credit__text" v-html="'Se il tuo credito welfare non è sufficiente troviamo insieme una soluzione. <strong>Contattaci!</strong>'" />
      <div class="cta-credit__btn">
        <a class="cta-credit__btn-link" :href="cta.cta_url" :target="cta.cta_target" v-for="(cta,cta_index) in text_cta" v-bind:key="cta_index">
          <vb-button
            :vb_options="{
              text: cta.cta_text,
              icon: cta.cta_icon,
              size: 'sm'
            }"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'cta-credit',
  props: {
  },
  components: {
  },
  data() {
    return {
      text_cta: [
        {
          cta_text: 'Chat',
          cta_icon: 'chat',
          cta_url: 'javascript:window.zE.activate()',
          cta_target: '_self'
        },
        {
          cta_text: 'Whatsapp',
          cta_icon: 'whatsapp',
          cta_url: this.get_config().site.waIntentLink,
          cta_target: '_blank'
        },
        {
          cta_text: 'Email',
          cta_icon: 'email',
          cta_url: 'mailto:'+this.get_config().site.emailSupport,
          cta_target: '_blank'
        }
      ]
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style lang="scss">

  .cta-credit {
    background-color: rgb($white, .8);
    border-radius: $border-radius-lg;
    padding: $grid-gutter-width/2;
    text-align: center;

    @include media-breakpoint-up(sm) {
      display: flex;
      align-items: center;
      background: linear-gradient(90deg, rgba(255,255,255) 0%, rgba(255,255,255,0) 100%);
      height: 150px;
      overflow: hidden;
      padding: 0 0 0 $grid-gutter-width*3;
      text-align: left;
    }

    &__image {
      transform: scaleX(-1);
      margin-bottom: 10px;
      height: 150px;

      @include media-breakpoint-up(sm) {
        margin-right: $grid-gutter-width;
        margin-top: $grid-gutter-width/2;
        margin-bottom: 0;
        height: 100%;
      }
    }

    &__title {
      font-size: $font-size-xl;
      background-size: contain;
      background-position: 0;
      padding-left: 35px;
    }

    &__btn {

      @include media-breakpoint-up(sm) {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }

      &-link {
        margin-right: 20px;

        @include media-breakpoint-up(sm) {
          margin-right: 10px;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

</style>